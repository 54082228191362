export const theme = {
	colors: {
		black: "#000",
		black_background: "#111",
		black_primary: "#2E2F30",
		black_text: "#53595C",
		blue_primary: "#4D9999",
		blue_secondary: "#EBF0F5",
		green_primary: "#4DD18F",
		green_secondary: "#399969",
		grey_app_background: "#F7F9FA",
		grey_border: "#DDE4ED",
		grey_light: "#EBF0F5",
		grey_primary: "#464646",
		grey_secondary: "#A6ACB3",
		pink_primary: "#E6007A",
		pink_secondary: "#C40061",
		red_light: "#FFF1F0",
		red_primary: "#FF5A47",
		red_secondary: "#D94C3D",
		white: "#FFF",
		white_transparent: "rgba(255, 255, 255, 0.2)"
	},
	fontSizes: {
		faq: "1.25rem",
		h1: "4.375rem",
		h2: "2.875rem",
		h3: "1.625rem",
		h4: "1.375rem",
		input_text_size: "1.4rem",
		lg: "1.8rem",
		md: "1.5rem",
		paragraph: "0.875rem",
		sm: "1.3rem",
		xl: "2.4rem",
		xs: "1.2rem",
		xxl: "3rem"
	},
	fonts: {
		font_default: "'Work Sans', -apple-system, sans-serif"
	},
	radii: {
		button_radius: "0.3rem",
		input_border_radius: "0.3rem"
	},
	shadows: {
		box_shadow_card: "0 0.2rem 0.4rem rgba(83, 89, 92, 0.15)",
		box_shadow_card_hover: "0 0.4rem 1.6rem rgba(83, 89, 92, 0.2)",
		box_shadow_sec_btn: "0 0 0 1px #706D6D inset",
		box_shadow_sec_btn_neg: "0 0 0 1px #D94C3D inset"
	}
};

import "semantic-ui-css/semantic.min.css";
import "../fonts.css"; /* as createGlobalStyle is not importing fonts*/

import { createGlobalStyle } from "@xstyled/styled-components";

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: 1em;
        scroll-behavior: smooth;
    }

    body {
        margin: 0;
        font-family: font_default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: white;
        color: black;
    }

    ul {
        padding: 0;
    }

    p {
        font-size: paragraph;
        line-height: 1.57;
        margin-bottom: 0.875rem;

        a {
            color: pink_primary;
            text-decoration: underline;
            &:hover {
                color: pink_secondary;
                text-decoration: none;
            }
        }
    }

    a:hover {
        text-decoration: none;
    }

    .container-fluid {
        max-width: 1200px;
        padding: 0 2.5rem 0 2.5rem;
        margin: 3.75rem auto 0 auto;

		@media (max-width: 1299px) {
			padding: 0 2.5rem 0 2.5rem;
		}
    }

    #page-container {
        position: relative;
        min-height: 100vh;
    }

    .bg-vote {
        background-color: black_background;
    }

    .bg-dark {
        background-color: #172026;
    }

    .ui.container {
        margin: 4rem auto 0 auto;
        padding-bottom: 8rem;

        @media only screen and (max-width: 1299px) and (min-width: 992px) {
            width: calc(100% - 4rem);
        }

        @media only screen and (min-width: 1176px) {
            width: 1112px;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        color: inherit;
        font-family: font_default;
        line-height: 100%;
        margin-top: 0;
        letter-spacing: -0.01em;
    }

    h1 {
        font-size: 3.25rem;
        @media (min-width: 35rem) {
            font-size: h1;
        }
        line-height: normal;
        font-weight: 700;
        text-transform: capitalize;
    }

    h2 {
        font-size: h2;
        line-height: 1;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 1rem !important;
    }

    h3 {
        font-size: h3;
        line-height: 1.08;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    h4 {
        font-size: h4;
        line-height: 1.27;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    strong {
        font-weight: 700;
    }

    .text-center {
        text-align: center;
    }

    .hidden {
        display: none;
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }

    .loading {
		overflow: hidden;
        pointer-events: none;
	}

    #background,
    #roadmap,
    #voting-mechanics,
    #vote,
    #faq {
		scroll-margin-top: 6rem;

        @media (min-width: 62rem) {
            scroll-margin-top: 0;
        }
	}

    ::selection {
        background-color: black_primary;
        color: white;
    }

    .ui.dropdown .menu, .ui.dropdown .menu>.item {
        font-size: sm;
    }

    @media only screen and (max-width: 768px) {
        .container-fluid {
        margin: 2.5rem auto 0 auto;
        padding: 0 1.25rem 0 1.25rem;
        }

        .ui.container, .ui.grid>.column:not(.row) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media only screen and (max-width: 576px) {
        .container-fluid {
            padding:0;
        }

        .ui.grid, .ui.container {
            margin-top: 0rem!important;
            margin-left: 0!important;
            margin-right: 0!important;
        }

        h3 {
            font-size: 2.1rem;
        }
    }

    /* Slider */

    .rc-slider {
        height: 50px;
    }

    .rc-slider-rail {
        background-color: #eaeaea;
        height: 5px;
        top: 50%;
        transform: translateY(-50%);
    }

    .rc-slider-step {
        height: 5px;
        height: 3.125rem;
        cursor: pointer;
    }

    .rc-slider-dot {
        width: 5px;
        height: 5px;
        border: 0;
        background: #999;
        top: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
    }

    .rc-slider-handle {
        margin-top: 0;
        top: 50%;
        transform: translate(-50%, -50%) !important;
    }

    /* Gradient background */
    @keyframes gradient {
        0%   { background-position: 0 50% }
        50%  { background-position: 100% 50% }
        100% { background-position: 0 50% }
    }
    .bg-gradient {
        animation: gradient 42s ease infinite;
        background: linear-gradient(128deg,#e73c7e,#c40061,#5d27cb,#e73c7e);
        background-size: 250% 250%;
    }
`;

/* eslint-disable sort-keys */
import styled from "@xstyled/styled-components";
import cx from "classnames";
import React, { useState } from "react";

interface Props {
	className?: string;
	title?: string;
	content?: any;
}

const Accordion = ({ className, title, content }: Props) => {
	const [expandedAccordionItem, toggleExpandedAccordionItem] = useState(false);

	return (
		<React.Fragment>
			<div className={className}>
				<div
					className={cx("accordion__content", {
						"accordion__content--active" : expandedAccordionItem
					})}
				>
					<h4 className="accordion__title" onClick={() => toggleExpandedAccordionItem(!expandedAccordionItem)}>
						{ title }
					</h4>
					<div className="accordion__body" dangerouslySetInnerHTML={{ __html: content }}></div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default styled(Accordion)`
	.accordion__content {
		padding: 1.5rem 0 0;
		border-bottom: 1px solid #E6E6E6;

    &--active {
      .accordion__title::after {
        transform: rotate(0);
      }
      .accordion__body {
        max-height: 100rem;
      }
    }
	}

	.accordion__title {
    position: relative;
		font-size: 1.25rem;
		line-height: 1.4;
		margin-bottom: 0.5rem;
		color: #E6007A;
		font-weight: 700;
    cursor: pointer;
    padding-right: 2.5rem;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0.25rem;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url("arrow-up.svg");
      background-position: right center;
      background-repeat: no-repeat;
      transform: rotate(-180deg);
      transition: transform 0.4s ease-in-out;
    }
	}

	.accordion__body,
	.accordion__body p,
	.accordion__body ol,
	.accordion__body ul {
		font-size: 1.25rem;
		line-height: 1.4;
		margin-top: 0.5rem;
		margin-bottom: 1.25rem;
		max-width: 55rem;
	}

  .accordion__body {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s ease-in-out;
  }
`;
const firebaseConfig = {
	apiKey: "AIzaSyC7xgtxLT5EBjX4VH6cjB9J3Mx9EpQGn8Y",
	appId: "1:341410118123:web:978893c1c52b70a09a01ae",
	authDomain: "polkadot-brand-vote.firebaseapp.com",
	messagingSenderId: "341410118123",
	projectId: "polkadot-brand-vote",
	storageBucket: "polkadot-brand-vote.appspot.com"
};

export default firebaseConfig;

import styled from "@xstyled/styled-components";
import React from "react";
import { Container, Grid } from "semantic-ui-react";

interface Props {
	className?: string;
}

const Countdown = ({ className }: Props) => {
	function handleClick(event: any) {
		event.preventDefault();
		let target = event.target.hash;
		target = target.substring(1);
		const element = document.getElementById(target);
		element!.scrollIntoView({ behavior: "smooth" });
	}
	return (
		<React.Fragment>
			<Container className={className}>
				<Grid relaxed>
					<Grid.Row className="countdown-container">
						<Grid.Column mobile={16} tablet={16} largeScreen={16} widescreen={16}>
							<h1>Vote on the future of Polkadot’s brand</h1>
							<div className="countdown__announcement">Voting closed</div>
							<div className="countdown__timer">
								Thanks to the 2000+ account holders who participated! The results are in, <a href="#vote-results" onClick={handleClick}>learn more</a> about how this historical vote took place.
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</React.Fragment>
	);
};

export default styled(Countdown)`
	text-align: center;

	h1 {
		margin: 0 auto;
		max-width: 44rem;
	}

	.countdown-container {
		padding-top: 11rem !important;
	}

	.countdown__announcement {
		margin: 4.5rem 0 2rem;
		display: inline-block;
		line-height: 1.375rem;
		border-bottom: 1px solid #E6007A;
		text-transform: uppercase;
	}

	.countdown__timer {
		font-size: 1.25rem;
		line-height: 1.25;
		font-weight: 700;
		text-transform: none;
		color: pink_primary;
		max-width: 37rem;
		margin: 0 auto;

		a {
			color: #E6007A;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		@media (min-width: 28rem) {
			font-size: 1.5rem;
		}

		@media (min-width: 32.5rem) {
			font-size: 2rem;
		}
	}
`;
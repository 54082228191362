import styled from "@xstyled/styled-components";
import React from "react";
import { Container, Grid } from "semantic-ui-react";

interface Props {
	className?: string;
}

function template(i: number) {
	return `
			&:nth-child(${i + 1}) {
				margin-left: ${`${1 * i}rem`};
			}
		`;
}
function addListMargins() {
	let str = "";
	for (let i = 0; i < 12; i += 1) {
		str += template(i);
	}
	return str;
}

const Roadmap = ({ className }: Props) => {

	return (
		<React.Fragment>
			<div className={className} id="roadmap">
				<div className="bg-gradient">
					<Container>
						<Grid relaxed>
							<Grid.Row className="roadmap-container">
								<Grid.Column mobile={16} tablet={16} largeScreen={6} widescreen={6}>
									<h2>Where are we on this journey?</h2>
								</Grid.Column>
								<Grid.Column mobile={16} tablet={16} largeScreen={1} widescreen={1}></Grid.Column>
								<Grid.Column mobile={16} tablet={16} largeScreen={9} widescreen={9}>
									<p>The aspiration to decentralize the Polkadot brand was initiated with an on-chain proposal approved on Jan. 4th, 2021, by the Polkadot Council. Since then, an immense amount of research, strategy, and design development has been undertaken, outlining challenges and opportunities for the Polkadot brand — from the confusion between Polkadot’s logo and that of other popular brands to the need to provide design tools for use by the community. The brand development process has been shared through key milestones that can be reviewed in the roadmap below.</p>
									<p>Currently, the project has reached a stage where there are two different brand directions that Polkadot can take—making it an opportune moment to explore community engagement.</p>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<Grid relaxed verticalAlign="bottom">
							<Grid.Row className="background-info-container" reversed="computer">
								<Grid.Column mobile={16} tablet={16} largeScreen={9} widescreen={9}>
									<h3>Brand Update Roadmap</h3>
									<ul className="roadmap-list">
										<li className="roadmap-list__item roadmap-list__item--previous">
											<a href="https://polkascan.io/polkadot/block/3093521" target="_blank" rel="noreferrer">
												Bounty On-chain Proposal
											</a>
										</li>
										<li className="roadmap-list__item roadmap-list__item--previous">
											<a href="https://www.dropbox.com/sh/7nwymn0rs42tjtp/AACSntxUFdJdOoRQl9GTJA2Oa?dl=0&lst=&preview=Polkadot+Bounty_26122020.pdf"  target="_blank" rel="noreferrer">
												Bounty Proposal Doc
											</a>
										</li>
										<li className="roadmap-list__item roadmap-list__item--previous">
											<a href="https://polkascan.io/polkadot/event/3109841-2" target="_blank" rel="noreferrer">
												On-chain Approval
											</a>
										</li>
										<li className="roadmap-list__item roadmap-list__item--previous">
											<a href="https://polkascan.io/polkadot/event/3191989-3" target="_blank" rel="noreferrer">
												On-chain Curator Selection
											</a>
										</li>
										<li className="roadmap-list__item roadmap-list__item--previous">
											<a href="https://www.youtube.com/watch?v=EJ3DuGgwfl8" target="_blank" rel="noreferrer">
												Update at Polkadot Decoded
											</a>
										</li>
										<li className="roadmap-list__item roadmap-list__item--previous">
											<a href="https://polkadot.polkassembly.io/bounty/0#4f1773e7-159c-4aa4-be6f-2594092c4fa7" target="_blank" rel="noreferrer">
												Bounty Close Report
											</a>
										</li>
										<li className="roadmap-list__item roadmap-list__item--previous">
											<a href="https://www.dropbox.com/s/h7k3am64nhglbuc/Polkadot_Brand_Audit_Shortened_210810.pdf?dl=0" target="_blank" rel="noreferrer">
												Immersion Playback Deck
											</a>
										</li>
										<li className="roadmap-list__item roadmap-list__item--previous">
											<a href="#">
												Brand On-chain Vote
											</a>
										</li>
										<li className="roadmap-list__item roadmap-list__item--current">
											<span>
												Brand Development
											</span>
										</li>
										<li className="roadmap-list__item roadmap-list__item--next">
											<span>
												Asset Delivery
											</span>
										</li>
										<li className="roadmap-list__item roadmap-list__item--next">
											<span>
												Update Polkadot
											</span>
										</li>
									</ul>
								</Grid.Column>
								<Grid.Column mobile={16} tablet={16} largeScreen={7} widescreen={7}>
									<h3>What happens afterwards?</h3>
									<p>This community vote aims to provide a clear direction for the brand moving forward through both the on-chain vote and qualitative feedback collected via the form. However, even after the voting has closed there is still much more work to be done, as the chosen direction still needs to be further developed and applied to practical assets (such as websites, social media, and so forth). Brands are amorphous and adapt differently across various touch-points, which is why none of the assets seen here should be considered final; they are mockups and are primarily used to give the community a picture of directions the brand could take.</p>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</div>
			</div>
		</React.Fragment>
	);
};

export default styled(Roadmap)`
	color: white;

	.ui.container {
		margin-top: 0;
		padding-top: 4rem;
	}

	.roadmap-container {
		margin-bottom: 5rem;
	}

	.roadmap-list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin: 2rem 0 4rem;
		padding: 0;

		&__item {
			position: relative;
			display: inline-block;
			width: auto;
			height: 2.125rem;
			line-height: 2.125rem;
			background-color: #fa0;
			padding: 0;
			border-radius: 2.125rem;
			font-size: 1rem;
			margin-bottom: 0.375rem;

			@media (min-width: 500px) {
				${addListMargins()}
			}

			a,
			span {
				border-radius: 2.125rem;
				position: relative;
				display: inline-block;
				padding: 0 1.5rem 0 2.5rem;
				z-index: 1;
				overflow: hidden;
				transition: all 0.2s ease-in-out;

				&::before {
					content: '';
					position: absolute;
					left: 0.375rem;
					top: 0.375rem;
					width: 1.375rem;
					height: 1.375rem;
					background-color: #e6007a;
					border-radius: 50%;
					border: 6px solid white;
					z-index: -1;
				}

				&::after {
					content: '';
					position: absolute;
					transition: all 0.4s ease;
					top: 0.375rem;
					left: 0.375rem;
					width: 1.375rem;
					height: 1.375rem;
					background: white;
					border-radius: 50%;
					z-index: -2;
				}
			}

			a {
				color: white;

				&:hover {
					color: black;

					&::after {
						transform: scale(25);
					}
				}
			}

			&--previous {
				background-color: rgba(0,0,0,0.1);
			}
			&--current {
				background-color: white;
				color: black;
			}
			&--next {
				background-color: rgba(255,255,255,0.1);

				span::before {
					background-color: white;
				}
			}

		}
	}
`;
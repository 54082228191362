import React from "react";

import firebase from "../firebase";

export interface FirebaseContextType {
	firebase: firebase.app.App;
}

export const FirebaseContext: React.Context<FirebaseContextType> = React.createContext({} as FirebaseContextType);

export interface FirebaseContextProviderProps {
	children?: React.ReactElement;
}

export function FirebaseContextProvider(props: FirebaseContextProviderProps): React.ReactElement {
	const { children = null } = props;

	return (
		<FirebaseContext.Provider value={{ firebase: firebase.app() } as FirebaseContextType}>
			{children}
		</FirebaseContext.Provider>
	);
}

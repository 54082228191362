import "rc-slider/assets/index.css";

import styled from "@xstyled/styled-components";
import React from "react";

import BackgroundInformation from "../components/BackgroundInformation";
import BrandVote from "../components/BrandVote";
import Countdown from "../components/Countdown";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Roadmap from "../components/Roadmap";
import SharePanel from "../components/SharePanel";
import VotingMechanics from "../components/VotingMechanics";

const Home = () => {

	return (
		<React.Fragment>
			<Header apiReady={true} />
			<Countdown />
			<BackgroundInformation/>
			<Roadmap/>
			<VotingMechanics/>
			<BrandVote/>
			<SharePanel/>
			<FAQ/>
			<Footer/>
		</React.Fragment>
	);
};

export default styled(Home)`
`;

/* eslint-disable sort-keys */
import "rc-slider/assets/index.css";

import styled from "@xstyled/styled-components";
import React, { useState } from "react";
import { Button, Container, Grid } from "semantic-ui-react";

import Accordion from "../components/Accordion";
import ModalVideo from "../components/ModalVideo";

const BrandVote = ({ className }: { className?: string }) => {

	const BrandVoteFaqData = [
		{
			title: "Why Update The Identity?",
			content: `
				<p>The identity is the overall look and feel of a brand, from graphical elements to animated interactions. It’s not a specific element, rather it’s the sum of all its parts that emerges to become something instantly identifiable. Specifically for Polkadot’s brand identity, it’s important that it can flex and adapt over time; this runs contrary to traditional centralized brands which enforce rigid rules to be consistently followed on fixed assets.</p>
				<p>At present, the Polkadot brand (as seen on <a target="_blank" rel="noreferrer" href="https://polkadot.network/">polkadot.network</a>) lacks breadth in its design language to address its growing needs. A strong identity for Polkadot is one that can be easily adapted and remixed by its community while at the same time retaining its core recognizability. This requires the creation of new design tools and systems that can be easily understood and used.</p>
			`
		},
		{
			title: "Why Update The Logo?",
			content: `
			<p>A strong logo design for Polkadot should be able to meet three high level points. One, is it iconic/recognizable? Two, is it extensible/composable? Three, is it meaningful/relatable? Additionally, there are practical factors that must also be addressed such as the design not looking similar to existing brands, being legible in different sizes, working on various user interfaces, and so forth—learn more in the <a href="#faq">FAQ</a>. As the research has shown, the current Polkadot logo falls short on multiple fronts. With the longevity and future of the network in mind, it’s beneficial to update the logo while still retaining as much of Polkadot’s existing brand equity as possible.</p>
			`
		}
	];

	const [isOpen, setOpen] = useState(false);
	const [videoUrl, setVideoUrl] = useState("");

	const openVideo = (url: string) => () => {
		setVideoUrl(url);
		setOpen(true);
	};

	return (
		<React.Fragment>
			<ModalVideo isOpen={isOpen} onClose={() => setOpen(false)} url={videoUrl} />
			<div className={className}>
				<div className="bg-gradient" id="vote">
					<Container className="container--gradient">
						<Grid>
							<Grid.Row>
								<Grid.Column mobile={16} tablet={16} largeScreen={7} widescreen={7}>
									<h2>What’s Being Voted On?</h2>
								</Grid.Column>
								<Grid.Column mobile={16} tablet={16} largeScreen={9} widescreen={9}>
									<p>Every DOT holder casts their vote using a single transaction which encompasses their preference for two separate parts of the brand: one vote for their logo design of preference and another for their brand identity of preference. Both categories have two directions to choose from.</p>
									<p>
										<strong>What’s shown here are not final assets, they are preliminary mockups to give an impression of the directions the brand could take.</strong>{" "}
										Further refinement and development will take place after the vote has closed. Importantly, the options presented have been developed so that the logo design and identity design can be voted on independent of one another. This gives you greater choice as as you can mix and match your preferences.
									</p>
									<p>Votes can be distributed on a sliding scale between the options (such as 75% for one, and 25% for the other). Additionally, as this experimental vote is far from being the optimal process for brand development, a form is also available for written feedback.</p>

									<div className="accordion">
										{BrandVoteFaqData.map(({ title, content }) => (<Accordion title={title} key={title} content={content} />))}
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</div>
				<div className="bg-vote" id="vote-results">
					<Container className="container--vote-intro">
						<Grid>
							<Grid.Row>
								<Grid.Column mobile={16} tablet={16} largeScreen={7} widescreen={7}>
									<h2>Vote<br/>Results</h2>
								</Grid.Column>
								<Grid.Column mobile={16} tablet={16} largeScreen={9} widescreen={9}>
									<p>Over a period of roughly nine days, the 2097 DOT holders voted on the future of Polkadot’s brand. Through quadratic voting, an overall voting power (VP) of 39,687 was put forward from a total of 10,309,474 DOT. This led to:</p>
									<ul className="vote-intro-list">
										<li><strong>Identity A</strong> — 43% (17,187.99 VP), <strong>Identity B</strong> — 57% (22,498.68 VP)</li>
										<li><strong>Logo A</strong> — 81% (32,272.94 VP), <strong>Logo B</strong> — 19% (7,413.729 VP)</li>
									</ul>
									<p>
										As all votes cast were remark transactions on Polkadot, results are publicly verifiable. Learn more about how the
										{" "}<a href="https://github.com/niklabh/brand-vote-result" target="_blank" rel="noreferrer">vote results were tallied here</a>.
										In addition to the on-chain brand vote, 207 feedback form responses were collected from the community.
									</p>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
					<Container>
						<Grid>
							<Grid.Column mobile={16} tablet={16} largeScreen={16} widescreen={16}>
								<div className="grid-container">
									<div className="grid-container__col grid-container__col--identity-a">
										<div className="option" onClick={openVideo("identity-a.mp4")}>
											<h4>Identity A – 43%</h4>
											<div className="card card--identity-a"></div>
											<p>This concept turns the humble dot into a bold, uncompromising, and iconic symbol, reflecting the vast scope of the Polkadot vision. This identity’s simple and clear focus on a large circular dot provides a broad canvas that can be pushed, pulled, and adapted in many directions. Click to watch. </p>
										</div>
									</div>
									<div className="grid-container__col grid-container__col--identity-b">
										<div className="option" onClick={openVideo("identity-b.mp4")}>
											<h4>Identity B – 57%</h4>
											<div className="card card--identity-b"></div>
											<p>Representing the breadth of Polkadot’s technology and communities, this direction is powered by a multitude of smaller dots coming together to form emergent designs and systems. This identity’s playful nature enables a wide range of expression and invention. Click to watch. </p>
										</div>
									</div>
									<div className="grid-container__col grid-container__col--logo-a">
										<div className="option" onClick={openVideo("logo-a.mp4")}>
											<h4>Logo A – 81%</h4>
											<div className="card card--logo-a"></div>
											<p>This direction explores decentralization through the notion that a polkadot pattern has no center. This design, inspired by the Polkadot Relay Chain, represents a connection of ecosystems. When viewed from certain perspectives, both the positive space (pink) and the negative space (white) form the outline of a dot. Click to watch.</p>
										</div>
									</div>
									<div className="grid-container__col grid-container__col--logo-b">
										<div className="option" onClick={openVideo("logo-b.mp4")}>
											<h4>Logo B – 19%</h4>
											<div className="card card--logo-b"></div>
											<p>Inspired by the concept of Polkadot as &apos;Layer 0&apos;, this direction uses the shape of a dot (above) connecting to a foundation (below) to reference the parachains &amp; communities built on top of Polkadot. The circular framing of this distinctive design shows a cropped-in view of the relationship of these two elements. Click to watch. </p>
										</div>
									</div>
									<div className="grid-container__col grid-container__col--vote">
										<div className="connect">
											<h3 className="info">The votes are in!</h3>
											<p>Thanks for taking part.<br />Voting is now closed. Keep an eye out – the outcome will be posted soon.</p>
											<div className="connect__action">
												<Button disabled className="button">
													Connect
												</Button>

												<p><a href="https://polkadot.js.org/extension/" target="_blank" rel="noreferrer">Install Polkadot.js Extension</a></p>
											</div>
										</div>
									</div>
								</div>
							</Grid.Column>
						</Grid>
					</Container>
				</div>
			</div>
		</React.Fragment>
	);
};

export default styled(BrandVote)`
	color: white;

	@keyframes spinnerAnimation {
		0%   {transform: rotate(0deg)};
		100% {transform: rotate(360deg)};
	}

	.loading-spinner {
		width: 2.5rem;
		height: 2.5rem;
		margin: 1rem;
		border-radius: 50%;
		background-color: transparent;
		border: 4px solid #e6007a;
		border-bottom-color: transparent;
		animation: spinnerAnimation 1s ease-in-out infinite;
		box-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 40px white, 0 0 40px white, 0 0 40px white, 0 0 40px white;
		background-color: white;
	}

	.vote-intro-list {
		margin-left: 1rem;
	}

	.container--gradient {
		margin-top: 0;
		padding-top: 4rem;

		p {
			color: white;
		}
	}

	.container--vote-intro {
		margin-top: 0 !important;
		padding-top: 4rem;
		padding-bottom: 2rem !important;

		p {
			color: #c4c4c4;
		}
	}

	.accordion__title {
		color: white;

		&::after {
			background-image: url("arrow-up-light.svg");
		}
	}

	.accordion__content {
		border-bottom: 1px solid #c4c4c4;
	}

	.accordion__body,
	.accordion__body p,
	.accordion__body ol,
	.accordion__body ul {
		font-size: paragraph;
	}

	.accordion__body p a {
		color: white;
	}

	.feedback {
		h3 {
			font-weight: 800;
			margin-bottom: 0.5rem;
		}

		p {
			color: white;
		}

	}

	h4 {
		font-size: 1.125rem;
		line-height: 1.75rem;
		margin-bottom: 1rem;
		color: white;
	}

	.grid-container {
		padding: 1.5rem;
		display: grid;
		gap: 2.5rem;
		grid-auto-flow: column;
		grid-template-columns: 1fr;
		grid-auto-flow: row;
		/* grid-template-rows: repeat(5, auto); */

		@media (min-width: 40rem) {
			padding: 0;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(4, auto);
		}

		@media (min-width: 62rem) {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, auto);
		}

		&__col {
			@media (min-width: 40rem) {
				&--identity-a { grid-area: 1 / 1 / 2 / 2; }
				&--identity-b { grid-area: 1 / 2 / 2 / 3; }
				&--logo-a { grid-area: 2 / 1 / 3 / 2; }
				&--logo-b { grid-area: 2 / 2 / 3 / 3; }
				&--vote { grid-area: 3 / 1 / 4 / 3; }
			}

			@media (min-width: 62rem) {
				&--vote { grid-area: 1 / 3 / 3 / 4; }
			}
		}
	}

	.option {
		&:hover {
			cursor: pointer;

			.card {
				transform: scale(1.05);
			}
		}
	}

	.card {
		position: relative;
		padding-top: 58.14%;
		margin: 0 0 1.5rem 0;
		border-radius: 10px;
		cursor: pointer;
		position: relative;
		background: white no-repeat center center / cover;
		transition: all 0.2s ease;

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.7);
		}

		&::after {
			content: "";
			display: block;
			position: absolute;
			width: 78px;
			height: 78px;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			background: url("play.svg") no-repeat;
		}

		&--logo-a {
			background-image: url("logo-a.png");
		}

		&--logo-b {
			background-image: url("logo-b.png");
		}

		&--identity-a {
			background-image: url("identity-a.png");
		}

		&--identity-b {
			background-image: url("identity-b.png");
		}
	}

	.vote,
	.connect {
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		max-width: 19rem;
		height: 37.5rem;
		margin: 2.75rem auto 0;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
	}

	.vote {
		background: #FFF;
		font-size: 1rem;
		line-height: 1.5rem;
		color: black;
		padding: 1.5rem 1.75rem;

		&__account {
			width: 100%;
		}
	}

	.connect {
		background: rgba(255, 255, 255, 0.05);
		text-align: center;
		align-items: center;
		padding: 0 1.75rem 10rem;

		&__action {
			position: absolute;
			left: 2rem;
			right: 2rem;
			bottom: 2.5rem;
		}

		&__error {
			width: 100%;
			position: relative;
			opacity: 0;
			pointer-events: none;

			p {
				position: absolute;
				top: 0;
				left: 0;
				color: red;
			}

			&--active {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}

	.info {
		text-align: center;
		color: #c4c4c4;
		font-weight: 700;
	}

	.button {
		position: relative;
		display: block;
		font-size: 1rem;
		height: 3.5rem;
		line-height: 1.5rem;
		padding: 1rem 1.5rem;
		background: white;
		border: 1px solid white;
		border-radius: 3.5rem;
		color: #e6007a;
		text-align: center;
		font-family: font_default;
		font-weight: 600;
		transition: all 0.2s ease;
		cursor: pointer;
		overflow: hidden;
		z-index: 1;
		width: 100%;
		margin: 0 0 1rem;
		outline: 0;

		&--vote {
			border-color: #e6007a;
			margin: 0;
		}

		&::before {
			content: "";
			height: 100%;
			position: absolute;
			top: 0;
			left: -3.5rem;
			width: 3.5rem;
			background: #e6007a;
			transition: all 0.2s ease;
			will-change: transform;
			z-index: -1;
			border: solid 1px #e6007a;
			border-radius: 3.5rem;
		}


		&:hover {
			color: white;
			border: 1px solid #e6007a;

			&::before {
				left: 0;
				width: 110%;
			}
		}

		&:active,
		&:focus {
			transform: translateY(2px);
			background-color: transparent;
			color: #e6007a;

			&:hover {
				color: white;
			}
		}
	}

	.bordered {
		width: 236px;
		border: 1px solid #E6007A;
		box-sizing: border-box;
		border-radius: 62px;
	}

	.button:disabled,
	.button[disabled]{
		border: 1px solid #C4C4C4;
		color: #C4C4C4;;
	}

	.end {
		margin-top: auto;
	}

	.accounts-error {
		margin: 0.75rem 0 3rem;
		color: red;
	}

	.balance {
		margin: 0.75rem 0 3rem;
		font-size: 12px;
		line-height: 24px;
		color: #999999;
	}

	.selectButton {
		border-radius: 50%;
		cursor: pointer;
		object-fit: cover;

		&--logo {
			object-fit: contain;
		}
	}

	.distribute {
		margin-bottom: 30px;
	}

	.tokenSelect {
		margin-bottom: 50px;
	}

	.selection {
		display: flex;
		justify-content: space-between;
	}

	.slider {
		width: 100px;
		padding-top: 0;
	}

	.selectValues {
		height: 54px;
		display: flex;
		justify-content: space-between;
		padding: 5px;
		font-size: 12px;
		color: #999999;
		padding: 6px 12px;
	}

	.insufficient {
		color: #E6007A;
	}

	@keyframes rotation {
		from {
		  transform: rotate(0deg);
		}
		to {
		  transform: rotate(359deg);
		}
	}

	.vote-cast-modal-bg {
		position: fixed;
		background: rgba(0,0,0,0.6);
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 20;
	}

	.vote-cast-modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: auto;
		min-width: 10rem;
		max-width: 58.75rem;
		padding: 2.5rem 1rem 1rem;
		box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.4);
		background: white;
		color: black;
		z-index: 30;

		@media (min-width: 51rem) {
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 2.5rem 1rem 3rem;
			top: 50%;
			left: 50%;
			right: auto;
			bottom: auto;
			transform: translate(-50%, -50%);
			border-radius: 20px;
		}

		&__close {
			position: absolute;
			right: 1rem;
			top: 1rem;
			padding: 0.5rem;
			cursor: pointer;

			@media (min-width: 51rem) {
				right: 2rem;
				top: 2rem;
			}
		}

		h1 {
			color: #111827;
			font-weight: 800;
			font-size: 2.5rem;
			line-height: 1.35;
			text-align: center;
			margin-bottom: 3rem;
			text-transform: none;
		}

		&__teaser-wrapper {
			@media (min-width: 51rem) {
				display: flex;
				flex: 1 auto;
				justify-content: center;
			}

			@media (min-width: 60rem) {
				padding: 0 4rem;
			}
		}

		&__teaser {
			display: flex;
			flex-direction: column;
			width: 100%;
			flex: 1;
			background: white;
			border: 1px solid #C4C4C4;
			border-radius: 20px;
			margin: 0 0 1.5rem;
			padding: 1.5rem;

			@media (min-width: 51rem) {
				width: 16rem;
				margin-left: 16px;

				&:nth-of-type(1) {
					margin-left: 0;
				}
			}


			h3 {
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				color: #000000;
				margin-bottom: 15px;
			}

			p {
				font-size: 16px;
				line-height: 24px;
			}

			.button {
				width: 100%;
				margin-bottom: 0;
				border-color: #e6007a;
			}
		}
	}
`;

import styled from "@xstyled/styled-components";
import React from "react";
import { Container, Grid } from "semantic-ui-react";

interface Props {
	className?: string;
}

const VotingMechanics = ({ className }: Props) => {
	function handleClick(event: any) {
		event.preventDefault();
		let target = event.target.hash;
		target = target.substring(1);
		const element = document.getElementById(target);
		element!.scrollIntoView({ behavior: "smooth" });
	}
	return (
		<React.Fragment>
			<div className={className} id="voting-mechanics">
				<Container>
					<Grid relaxed>
						<Grid.Row className="background-info-container">
							<Grid.Column mobile={16} tablet={16} largeScreen={7} widescreen={7}>
								<h2>How does the vote work?</h2>
								<h4 className="mechanics__description">
									As this is blockchain technology, it’s only fitting that the vote takes place
									on-chain. DOT holders can participate by{" "}
									<a href="#vote-results" onClick={handleClick}>casting their vote here</a> via the Polkadot.js
									Browser Extension. Follow the prompts on screen,{" "}
									<a
										href="https://www.youtube.com/watch?v=mFGYodvQYhI"
										target="_blank"
										rel="noreferrer"
									>
										watch this video
									</a>
									, or check out the{" "}
									<a href="#faq" onClick={handleClick}>
										FAQ
									</a>
									.
								</h4>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={16} largeScreen={9} widescreen={9}>
								<div className="mechanics-cards">
									<div className="mechanics-cards__card">
										<h3>Why you should vote</h3>
										<p>
											Your vote has <strong>real consequences</strong>. Decide the brand
											direction. It’s in your hands.
										</p>
										<p>
											<strong>Take part in this experience</strong>. Governance and community
											decisions are a core element of the Polkadot vision.
										</p>
										<p>
											Be part of <strong>Polkadot’s history</strong>. Record your vote on-chain.
										</p>
									</div>
									<div className="mechanics-cards__card">
										<h3>How the vote is designed</h3>
										<p>
											<strong>100% On-chain:</strong> Paving the way for a decentralized future.
										</p>
										<p>
											<strong>Community Power:</strong> DOT holders have their tokens translate
											into influence.
										</p>
										<p>
											<strong>Skin In The Game:</strong> Voting power is relative to the account
											balance.
										</p>
										<p>
											<strong>Power To the People:</strong> Quadratic voting favors small fish and
											mitigates the splash of big whales.
										</p>
									</div>
								</div>
								<h4>What does the voting process look like?</h4>
								<ol className="mechanics-list">
									<li>Vote opens and a snapshot of all Polkadot accounts & balances is taken.</li>
									<li>
										DOT holders pick their preferred Polkadot identity direction and logo direction.
									</li>
									<li>Votes are cast using the remark function with a minimal transaction cost.</li>
									<li>
										Nine days later, votes are closed, counted, and weighted by their account balance.
									</li>
								</ol>
								<p>
									As this brand vote utilizes Polkadot’s blockchain technology, all votes are
									anonymous, transparent, and publicly verifiable. For further details, please see the{" "}
									<a href="#faq" onClick={handleClick}>
										FAQ
									</a>{" "}
									below.
								</p>

								<div className="mechanics__weighted-voting">
									<h4>How does the weighted voting work?</h4>
									<p>
										<a
											href="https://www.radicalxchange.org/concepts/quadratic-voting/"
											target="_blank"
											rel="noreferrer"
										>
											Quadratic Voting
										</a>{" "}
										gives more power to small fish rather than big whales. Each vote is weighted by
										taking the square root of the account balance. As an example, 1 DOT has a voting
										power of 1, 25 DOT has a voting power of 5, 100 DOT has a voting power of 10,
										and so forth.
									</p>
									<p>
										The vote is weighted based on the account balance the moment the snapshot was
										taken at the opening of this brand vote. This is so that malicious actors cannot
										split their tokens into multiple accounts to maximize their voting power.
										Importantly, this means that no new accounts or changes to existing account
										balances after the snapshot will have any influence on the vote.
									</p>
								</div>

								<img className="mechanics__image" src="mechanics.png" />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default styled(VotingMechanics)`
	.ui.container {
		margin-top: 0;
		padding-top: 4rem;
	}
	.mechanics__description {
		max-width: 50rem;
		margin-bottom: 3rem;

		a {
			color: pink_primary;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		@media (min-width: 1200px) {
			max-width: 23.5rem;
		}
	}

	.mechanics__weighted-voting {
		margin: 4.5rem 0 3rem;
	}

	.mechanics__image {
		display: block;
		margin: 0 auto;
		width: 100%;

		@media (min-width: 1200px) {
			margin: 0;
		}
	}

	.mechanics-cards {
		display: grid;
		gap: 2rem;
		@media (min-width: 640px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		margin-bottom: 5rem;

		&__card {
			background: linear-gradient(74.98deg, #e6007c 39.06%, #b800e6 100%);
			box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
			border-radius: 1rem;
			color: white;
			padding: 1rem 1rem 2rem;
		}
	}

	.mechanics-list {
		@media (min-width: 640px) {
			columns: 2;
			column-gap: 2.5rem;
		}
		margin: 1.5rem 0;
		padding: 0;

		li {
			counter-increment: counter;
			position: relative;
			display: inline-block;
			width: 100%;
			list-style: none;
			padding: 0 0 1.5rem 3.5rem;
			font-size: 1.125rem;
			line-height: 1.34;

			&:before {
				content: counter(counter);
				position: absolute;
				left: 0;
				top: 0;
				width: 2.25rem;
				height: 2.25rem;
				line-height: 2.25rem;
				text-align: center;
				color: white;
				font-size: 1rem;
				font-weight: 800;
				border-radius: 50%;
				background: linear-gradient(74.98deg, #e6007c 39.06%, #b800e6 100%);
			}
		}
	}
`;

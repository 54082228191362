/* eslint-disable sort-keys */
import styled from "@xstyled/styled-components";
import cx from "classnames";
import React, { useState } from "react";
import { Container, Grid } from "semantic-ui-react";

import Accordion from "./Accordion";

interface Props {
	className?: string;
}

const FAQ = ({ className }: Props) => {

	const BrandVoteData = [
		{
			title: "What are the results from the Polkadot brand vote?",
			content: `
				<p><strong>Identity A</strong> — 43% (17,187.99 VP), <strong>Identity B</strong> — 57% (22,498.68 VP)</p>
				<p><strong>Logo A</strong> — 81% (32,272.94 VP), <strong>Logo B</strong> — 19% (7,413.729 VP)</p>
				<p><strong>Feedback Form responses:</strong> 207</p>
				<p>A total sum of 10,309,474 DOT has been used during the voting process by 2097 accounts. Due to the quadratic voting mechanism, the overall voting power equals 39,686.67. As all votes cast were remark transactions on Polkadot, results are publicly verifiable. Learn more about how the <a href="https://github.com/niklabh/brand-vote-result" target="_blank" rel="noreferrer">vote results were tallied here</a>.</p>
			`
		},
		{
			title: "Can all DOT holders participate in the vote?",
			content: `
				<p>Only Polkadot accounts that were created before the brand vote went live are able to participate in the vote. This is an intentional design decision to counter malicious actors gaming the system by splitting their tokens into multiple accounts in order to maximize their voting power. Importantly, as a side effect, no new accounts or changes to account balances after the vote went live will have any influence on the vote.</p>
				<p>In short, only existing DOT holders can participate in the brand vote. Do not create a new account and secure tokens to cast your vote — it will not be counted towards the outcome.</p>
			`
		},
		{
			title: "How do I vote?",
			content: `
				<p>You can watch <a href="https://www.youtube.com/watch?v=mFGYodvQYhI" target="_blank" rel="noreferrer">the following video</a> or follow the instructions below.</p>
				<ol>
					<li>Download and install the Polkadot.js Browser Extension</li>
					<li>Connect your Polkadot account.</li>
					<li>Select your voting preferences for both the logo and identity. You may choose to fully commit to one option (e.g. 100% and 0%) or you could split your preferences on a sliding scale (e.g. 25% and 75%). A single remark transaction will encompass your selected voting preferences for both the logo and identity. Do not vote or sign a remark transaction twice. </li>
					<li>Cast your vote and confirm your transaction.</li>
					<li>You can verify your vote by using a block explorer like Polkascan or Subscan.</li>
			`
		},
		{
			title: "Can I vote with a different wallet?",
			content: `
				<p>Yes. The Polkadot.js Browser Extension is only one approach. Any wallet that is able to make a remark call on Polkadot can be used. <a href="https://polkadot.polkassembly.io/post/669" target="_blank" rel="noreferrer">See here</a>.</p>
			`
		},
		{
			title: "Does it cost anything to vote?",
			content: `
				<p>Yes, however it is a minimal amount of DOT. The cost should be displayed on screen before signing the transaction; it is calculated based on the size of the remark call. As an estimation, the vote should cost roughly 0.0202 DOT or 20.24 milliDOT</p>
			`
		},
		{
			title: "Why and how are votes weighted?",
			content: `
				<p>In order to encourage wide community participation with the Polkadot brand, votes are weighted using a quadratic formula which effectively provides more voting power to small accounts relative to bigger accounts. While all Polkadot token holders can vote, this gives smaller players a greater chance to have their voice heard.</p>
				<p>Each vote is weighted by taking the square root of the account balance. As an example, 1 DOT has a voting power of 1, 25 DOT has a voting power of 5, 100 DOT has a voting power of 10, and so forth.</p>
				<p>The account balance used in the calculation was taken at the opening of the brand vote, at block number #7442640, around 3pm CET on Wednesday 27th. All changes to account balance before and after this snapshot will have no influence on the vote outcome; this is a measure to prevent new accounts and balances being created to take advantage of the weighted votes.</p>
			`
		},
		{
			title: "Can I vote multiple times?",
			content: `
				<p>Each Polkadot account's vote will only be counted once. If you vote a second time with the same account, only the newest vote will be counted (as it will override any prior votes from that account).</p>
				<p>As Polkadot accounts are permissionless and anonymous, if you control multiple accounts, you can vote with each of them. They will be counted and weighted independently as long as they existed prior to the brand vote going live.</p>
			`
		},
		{
			title: "How long is the voting period open for?",
			content: `
				<p>The voting period will close at block number 7577250. We estimate that this will occur in 9 days at around 12am CET on Saturday, November 6th.</p>
			`
		},
		{
			title: "Is the vote anonymous?",
			content: `
				<p>Yes. As this vote is on the Polkadot blockchain, it is completely anonymous unless you've chosen to associate your real identity with your on-chain account.</p>
			`
		},
		{
			title: "Are you storing my personal data?",
			content: `
				<p>No. Nothing besides anonymous arbitrary vote data is stored on the Polkadot network.</p>
			`
		},
		{
			title: "How do I know I'm not sending my DOT away?",
			content: `
				<p>As with all blockchain transactions, the security measures you take during the brand vote should be no different to your usual protocol, such as verifying the transaction details before signing it, ensuring your wallet is properly secured, double checking you're accessing the correct domains, and so forth. Each individual's security steps are dependent on their own needs and circumstances.</p>
				<p>Learn more about avoiding scams <a href="https://support.polkadot.network/support/solutions/articles/65000170305-beware-of-scams-&sa=D&source=docs&ust=1635275364034000&usg=AOvVaw1klS8bYTDGuK9A4puoGM3y" target="_blank" rel="noreferrer">here</a>.</p>
			`
		},
		{
			title: "Can I vote with a Proxy Account?",
			content: `
				<p>Whichever account sends the remark transaction on-chain will be counted as part of the vote. This means if a proxy account signs the remark transaction, its respective balance will be used. Alternatively, you can use the proxy account to trigger the remark transaction on another account; in this case, the balance of the second account would be part of the vote calculation.</p>
			`
		},
		{
			title: "Can I vote with a Multi-Sig Account?",
			content: `
				<p>Yes. The balance of the account that makes the remark transaction on-chain will be counted as part of the vote. This means if a multi-sig account signs the remark transaction, its balance will be used and voting power awarded accordingly.</p>
			`
		},
		{
			title: "Are any of my DOT locked if I take part in voting?",
			content: `
				<p>No, there is a small transaction fee to pay of around 0.0202 DOT, but otherwise voting does not affect your DOT balance in any way.</p>
			`
		},
		{
			title: "Can I vote if my DOT are locked for staking?",
			content: `
				<p>The snapshot of your account taken before the vote includes your staked DOT, adding to your voting power. However, there is a small transaction cost, so you need around 0.0202 DOT free to pay this fee.</p>
			`
		},
		{
			title: "I'm still struggling on the vote, where can I get more help?",
			content: `
				<p>Visit our <a href="https://support.polkadot.network/" target="_blank">support page</a>.</p>
			`
		}
	];

	const BrandDevelopmentData = [
		{
			title: "Did the Polkadot Council approve this initiative?",
			content: `
				<p>Yes. This initiative was approved on-chain by the Polkadot Council. Please see the <a href="#roadmap">roadmap</a> to gain an overview of the process. </p>
			`
		},
		{
			title: "Who designed these brand direction options?",
			content: `
				<p>As part of the first <a href="https://polkadot.polkassembly.io/motion/50" target="_blank" rel="noreferrer">on-chain bounty</a> approved by Polkadot Council, Koto Studio was selected as the team to develop the Polkadot brand by the bounty curators. To learn more about the bounty process, please <a href="#roadmap">click here</a>.</p>
			`
		},
		{
			title: "Who was involved in the discussions and decision making?",
			content: `
				<p>As outlined in the <a href="#roadmap">roadmap</a>, months of research and feedback was taken throughout the process, including formal interviews of 28 Polkadot stakeholders, a public community survey of ~200 responses, and so forth. As part of the on-chain bounty, four curators from different parts of the Polkadot ecosystem were selected to steward the process and work of Koto Studio. Please see the roadmap to gain an overview of the process.</p>
			`
		},
		{
			title: "How can I provide input and feedback?",
			content: `
				<p>[Update: feedback on this phase is now closed.] There are two ways to make your voice heard. Firstly and most importantly, vote with your tokens on your direction of preference. Secondly, you can send a message via this form as qualitative feedback.</p>
			`
		},
		{
			title: "Is this the final brand design?",
			content: `
				<p>No. What you see here are two directions that are possible avenues forward, but there is still much more refinement that needs to happen afterwards. Additionally, brands are not static and must continue to evolve across different touch-points over time. Therefore, in the future there may be differences in the brand execution as it gets applied to real assets "in the wild". This is a normal and expected development whenever a brand is being updated and adapted. Additionally, all feedback submitted will be factored into the development process following the brand vote.</p>
			`
		},
		{
			title: "When will we see the new Polkadot brand in action?",
			content: `
				<p>Once the voting period has closed, brand development is slated to proceed immediately after. A definitive date or timeline is not easy to forecast as updating assets across a large and decentralized ecosystem happens at an uneven pace.</p>
			`
		},
		{
			title: "Can I use the Polkadot brand for my own projects?",
			content: `
				<p>Once the vote results and community feedback are in, the next stage of the roadmap aims to develop the chosen brand direction by producing design tools and publicly accessible assets (such as an open-source font) for the Polkadot community. None of the assets presented at this stage are final and are merely mockups to provide an idea of each direction.</p>
			`
		},
		{
			title: "Why is the Polkadot identity being updated?",
			content: `
				<p>Brand identities are not static and naturally evolve over time. With the rapid expansion of the Polkadot ecosystem, this need to evolve and meet the needs of the community has grown ever stronger. During the design development of the two brand directions, a strong focus was made on ensuring the brand is as adaptable as possible. As an example of this, the goal is to produce design tools that can be adopted and remixed rather than rigid guidelines to be adhered to.</p>
				<p>Instilling flexibility on a fundamental level ensures the brand is able to accommodate many different use cases and needs in the future as the Polkadot network expands into uncharted territory. On top of this, the accessibility of the identity design encourages participation from those in the community.</p>
			`
		},
		{
			title: "Why is the Polkadot logo being updated?",
			content: `
				<p>Currently, the 'P' used to represent the DOT token and the Polkadot logo encounters significant brand and design challenges. Some examples of these are: mistaking the DOT token design with brands such as Pinterest and others, the logo losing recognizability in small sizes across different user interfaces, and the lack of extensibility in remixes by the community. This creates significant obstacles to widespread adoption.</p>
				<p>Solving real brand challenges while also creating a design that everybody in the community loves is an incredibly difficult task. On top of that, circles and dots are so ubiquitously used in blockchain logos that brands have become indistinguishable from one another.</p>
				<p>Updating logos is always a controversial matter that can generate strong reactions — especially when one has grown accustomed to the past and present design. However, research showed that evolving Polkadot's brand to address these notable obstacles is of great value for the future of the network.</p>
			`
		},
		{
			title: "Why is the identity and logo voted on separately?",
			content: `
				<p>While it's simpler to match one logo with one identity, this provides voters with less choice and constrains the possibilities of the brand. Because each direction presented meets the design brief and is fundamentally aligned with the Polkadot brand, they are naturally compatible with each other. Thus, the logo and identity can be mixed and matched to one's preference.</p>
			`
		}
	];

	const [activeTab, setActiveTab] = useState(1);

	return (
		<React.Fragment>
			<div className={className} id="faq">
				<Container>
					<Grid relaxed>
						<Grid.Row>
							<Grid.Column mobile={16} tablet={16} largeScreen={16} widescreen={16}>
								<h2 className="text-center">FAQ</h2>
								<p className="faq-description">Questions on the voting process below are for reference only as the voting is now closed.</p>
								<div className="tabs-navigation">
									<a
										className={cx("tabs-navigation__link", { "tabs-navigation__link--active" : activeTab === 1 })}
										onClick={() => setActiveTab(1)}
									>
										Brand Vote
									</a>
									<a
										className={cx("tabs-navigation__link", { "tabs-navigation__link--active" : activeTab === 2 })}
										onClick={() => setActiveTab(2)}
									>
										Brand Development
									</a>
								</div>
								{ activeTab === 1 && (
									<div className="accordion">
										{BrandVoteData.map(({ title, content }) => (<Accordion title={title} key={title} content={content} />))}
									</div>
								)}

								{ activeTab === 2 && (
									<div className="accordion">
										{BrandDevelopmentData.map(({ title, content }) => (<Accordion title={title} key={title} content={content} />))}
									</div>
								)}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default styled(FAQ)`
	.ui.container {
		margin-top: 0;
		padding-top: 4rem;
	}

	.faq-description {
		max-width: 32.5rem;
		margin: 3rem auto;
		text-align: center;
		font-size: faq;
		color: #A0A0A0;
	}

	.tabs-navigation {
		margin-bottom: 4rem;

		&__link {
			position: relative;
			display: inline-block;
			margin-right: 2rem;
			font-size: faq;
			font-weight: 700;
			color: #6C757D;
			cursor: pointer;
			padding: 0.75rem 0;
			transition: all 0.2s ease;

			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 100%;
				height: 4px;
				background-color: #E6007A;
				transition: all 0.4s ease;
			}

			&:hover {
				color: black;
			}

			&--active {
				color: black;

				&::before {
					right: 0;
				}
			}
		}
	}
`;

import "firebase/database";

import firebase from "firebase/app";

import firebaseConfig from "./config/firebase";

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

export default firebase;

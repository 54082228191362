import { ThemeProvider } from "@xstyled/styled-components";
import React from "react";
import ReactDOM from "react-dom";

import { GlobalStyle } from "./components/GlobalStyle";
import { FirebaseContextProvider } from "./context/FirebaseContext";
import { theme } from "./theme";
import Home from "./views/Home";

ReactDOM.render(
	<React.StrictMode>
		<FirebaseContextProvider>
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				<Home />
			</ThemeProvider>
		</FirebaseContextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

import styled from "@xstyled/styled-components";
import cx from "classnames";
import React, { useEffect, useState }  from "react";
import { Container } from "semantic-ui-react";

interface Props {
	className?: string;
	apiReady: boolean;
}

function template(i: number) {
	return `
			&:nth-child(${i + 1}) {
				transition-delay: ${`${50 * i}ms`};
			}
		`;
}
function addTransitionDelay() {
	let str = "";
	for (let i = 0; i < 8; i += 1) {
		str += template(i);
	}
	return str;
}

const Header = ({ className, apiReady }: Props) => {
	const [showHeader, setShowHeader] = useState(true);
	const [scrollPos, setScrollPos] = useState(window.pageYOffset);
	const [isOpen, setIsOpen] = useState(false);

	function handleClick(event:any) {
		event.preventDefault();
		let target = event.target.hash;
		target = target.substring(1);
		const element = document.getElementById(target);
		element!.scrollIntoView({ behavior: "smooth" });
		setIsOpen(false);
	}

	function handleScroll() {
		setScrollPos(window.pageYOffset);
		if (window.pageYOffset < scrollPos && !showHeader) {
			setShowHeader(true);
		} else if (window.pageYOffset > scrollPos && showHeader) {
			setShowHeader(false);
		}
	}

	useEffect (() => {
		function watchScroll() {
			window.addEventListener("scroll", handleScroll);
		}
		watchScroll();
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	return (
		<React.Fragment>
			<div className={className}>
				<div className={cx("header", {
					"header--hidden" : !showHeader,
					"header--open" : isOpen,
					"header--ready" : apiReady,
					"header--scrolled" : scrollPos > 0
				})}
				>
					<Container>
						<a href="/" className="header__logo-link">
							<img className="header__logo" src="logo-polkadot.svg" />
						</a>
						<ul className="header-navigation">
							<li><a onClick={handleClick} href="#background">Background</a></li>
							<li><a onClick={handleClick} href="#roadmap">Roadmap</a></li>
							<li><a onClick={handleClick} href="#voting-mechanics">Voting Mechanics</a></li>
							<li><a onClick={handleClick} href="#vote-results">Vote Results</a></li>
							<li><a onClick={handleClick} href="#faq">FAQ</a></li>
							<li><a className="button">Voting Has Closed</a></li>
						</ul>
						<button
							type="button"
							className="header__toggle"
							onClick={() => setIsOpen(!isOpen)}
						>
							<div className="nav-icon">
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
							<span className="sr-only">Open main menu</span>
						</button>
					</Container>
				</div>
			</div>
		</React.Fragment>
	);
};

export default styled(Header)`
	@keyframes headerFadeIn {
		0%   {opacity: 0;};
		100% {opacity: 1};
	}

	.ui.container {
		position: relative;
		padding: 0;
		margin-top: 0;
		margin-bottom: 0;
	}
	
	.row {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	.header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background-color: white;
		z-index: 10;
		height: 6rem;
		transform: translate(0, 0);
		border-bottom: 1px solid white;
		transition: all 0.3s ease-in-out;
		box-shadow: 0 0 0 rgba(0,0,0,0);
		animation: headerFadeIn 0.4s ease;

		&--scrolled {
			border-bottom: 1px solid #efefef;
		}

		@media (max-width: 991px) {
			&--open {
				border-bottom: 1px solid #efefef;

			}
		}

		@media (max-width: 67.5rem) {
			&::before {
				content: "";
				display: block;
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				height: 100vh;
				background-color: white;
				pointer-events: none;
				opacity: 0;
				transition: .25s ease-in-out;
			}
			&--open {
				&::before {
					opacity: 1;
				}
			}
		}

		@media (min-width: 67.5rem) {
			&--scrolled {
				box-shadow: 0 0.25rem 1rem rgba(0,0,0,0.05);
			}
			&--hidden {
				transform: translate(0, -6rem);
				box-shadow: 0 0 0 rgba(0,0,0,0);
			}
		}
	}

	.header__logo-link {
		position: absolute;
		top: 0;
		left: 1.5rem;
		display: inline-block;
		margin: 1.25rem 0 0 0;
		padding: 0.5rem 0;

		@media (min-width: 67.5rem) {
			left: 0;
		}
	}

	.header__logo {
		display: inline-block;
		height: 2.438rem;

		@media (min-width: 67.5rem) {
			height: 2.438rem;
		}
	}

	.header-navigation {
		position: absolute;
		top: 6rem;
		left: 0;
		right: 0;
		display: block;
		margin: 0;
		/* background: white; */
		height: calc(100vh - 6rem);
		opacity: 0;
		transition: .25s ease-in-out;
		pointer-events: none;
		opacity: 0;

		@media (min-width: 67.5rem) {
			display: inline-block;
			position: absolute;
			left: auto;
			top: 0;
			right: 0;
			margin: 1.5rem 0 0;
			height: auto;
			opacity: 0;
			pointer-events: none;
		}

		li {
			display: block;
			opacity: 0;
			transition: .25s ease-in-out;
			transform: translate(0,-2rem);
			${addTransitionDelay()}
			
			@media (min-width: 67.5rem) {
				display: inline-block;
				vertical-align: top;
				opacity: 1;
				transform: translate(0,0);
				display: inline-block;
				transition-delay: 0s;
			}
		}

		li:nth-last-child(2) {
			margin-right: 1.25rem;
		}

		a {
			display: block;
			color: black;
			font-size: h4;
			padding: 1.5rem;
			margin: 0;
			border-bottom: 1px solid #efefef;
			/* background: #0fa; */
			
			@media (min-width: 67.5rem) {
				display: inline-block;
				position: relative;
				color: black;
				font-size: 1rem;
				padding: 1rem;
				margin: 0 0.25rem;
				border-bottom: none;

				&::before {
					content: "";
					position: absolute;
					bottom: 0.75rem;
					left: 1rem;
					right: 100%;
					height: 2px;
					background-color: #E6007A;
					transition: all 0.4s ease;
				}

				&:hover {
					&::before {
						right: 1rem;
					}
				}
			}
		}
	}

	.header--ready .header-navigation {
		@media (min-width: 67.5rem) {
			opacity: 1;
			pointer-events: auto;
		}
	}

	.button {
		position: relative;
		display: inline-block;
		border-radius: 3.5rem;
		box-shadow: inset 0 0 0 1px #BFBFBF;
		overflow: hidden;
		z-index: 1;
		margin: 1.5rem !important;
		text-align: center;
		color: #BFBFBF !important;
		font-weight: 700;
		outline: 0;
		
		@media (min-width: 67.5rem) {
			margin: 0 !important;
			height: 51px;
			
			&::before {
				display: none;
			}

			&::after {
				content: "";
				height: 100%;
				position: absolute;
				top: 0;
				left: -3.5rem;
				width: 3.5rem;
				background: #E6007A;
				transition: all 0.2s ease;
				will-change: transform;
				z-index: -1;
				border-radius: 3.5rem;
			}
	
			/* &:hover {
				color: white !important;
	
				&::after {
					left: 0;
					width: 110%;
				}
			}
	
			&:active {
				transform: translateY(2px);
			} */
		}

	}

	.header__toggle {
		position: absolute;
		top: 2rem;
		right: 1.5rem;
		background-color: transparent;
		border: none;
		padding: 0.5rem;

		@media (min-width: 67.5rem) {
			display: none;
		}
	}

	.nav-icon {
		width: 24px;
		height: 24px;
		position: relative;
		cursor: pointer;
		background-color: transparent;

		span {
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background: black;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .25s ease-in-out;

			&:nth-child(1) {
				top: 2px;
			}

			&:nth-child(2),
			&:nth-child(3) {
				top: 11px;
			}

			&:nth-child(4) {
				top: 20px;
			}
		}
	}

	.header--open {
		.header-navigation {
			opacity: 1;
			pointer-events: auto;

			li {
				opacity: 1;
				transform: translate(0,0);
			}
		}

		.nav-icon span {
			&:nth-child(1) {
				top: 18px;
				width: 0%;
				left: 50%;
			}
	
			&:nth-child(2) {
				transform: rotate(45deg);
			}
	
			&:nth-child(3) {
				transform: rotate(-45deg);
			}
	
			&:nth-child(4) {
				top: 18px;
				width: 0%;
				left: 50%;
			}
		}
	}
	
`;
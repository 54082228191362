import styled from "@xstyled/styled-components";
import React from "react";
import { Container, Grid } from "semantic-ui-react";

interface Props {
	className?: string;
}

const Footer = ({ className }: Props) => {
	return (
		<React.Fragment>
			<div className={className}>
				<div className="bg-dark">
					<Container>
						<Grid relaxed>
							<Grid.Row>
								<Grid.Column mobile={16} tablet={16} largeScreen={16} widescreen={16}>
									<img className="footer__logo" src="logo-polkadot-light.svg" />
									<ul className="footer-navigation">
										<li>
											© 2021{" "}
											<a href="https://web3.foundation/" target="_blank" rel="noreferrer">
												Web3 Foundation
											</a>
										</li>
										<li>
											<a
												href="https://polkadot.network/impressum"
												target="_blank"
												rel="noreferrer"
											>
												Impressum
											</a>
										</li>
										<li>
											<a
												href="https://polkadot.network/disclaimer"
												target="_blank"
												rel="noreferrer"
											>
												Disclaimer
											</a>
										</li>
										<li>
											<a href="https://polkadot.network/privacy" target="_blank" rel="noreferrer">
												Privacy
											</a>
										</li>
									</ul>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</div>
			</div>
		</React.Fragment>
	);
};

export default styled(Footer)`
	color: white;

	.ui.container {
		padding: 2.25rem 0 0;

		@media (min-width: 48rem) {
			padding: 2.25rem 0 0;
		}
	}

	.footer__logo {
		display: inline-block;
		height: 1.938rem;
		margin-right: 3rem;
		margin-bottom: -0.275rem;

	
	}

	.footer-navigation {
		display: inline-block;
		font-size: 0.75rem;

		@media (min-width: 48rem) {
			font-size: 0.875rem;
		}

		li {
			color: #b5aeae;
			display: inline-block;

			&:last-of-type a::after {
				display: none;
			}
		}

		a {
			color: #b5aeae;

			&::after {
				content: "|";
				margin: 0 0.375rem;
				opacity: 0.5;
			}

			&:hover {
				color: white;
			}
		}
	}
`;

import styled from "@xstyled/styled-components";
import React from "react";
import { Container, Grid } from "semantic-ui-react";

interface Props {
	className?: string;
}

const voteUrl = "http://vote.polkadot.network";
const handleFocus = (event:any) => event.target.select();
const handleCopy = () => navigator.clipboard.writeText(voteUrl);

const SharePanel = ({ className }: Props) => {
	return (
		<React.Fragment>
			<div className={className}>
				<div className="bg-gradient">
					<Container>
						<Grid relaxed>
							<Grid.Row>
								<Grid.Column mobile={16} tablet={16} largeScreen={8} widescreen={8} className="share-container text-center">
									<h4>Share with friends!</h4>
									<p>Help spread the word about this great initiative to the Polkadot community by using the link below.</p>
									<div className="share">
										<input className="input" type="text" value={voteUrl} onFocus={handleFocus} readOnly />
										<button className="button" onClick={handleCopy}>Copy</button>
									</div>
								</Grid.Column>
								<Grid.Column mobile={16} tablet={16} largeScreen={8} widescreen={8} className="center-content text-center">
									<h4>Feedback closed</h4>
									<div className="share">
										<button disabled className="button">Drop feedback</button>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</div>
			</div>
		</React.Fragment>
	);
};

export default styled(SharePanel)`
	color: white;

	.ui.container {
		margin-top: 0;
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;
	}

	.share-container {
		margin-bottom: 4rem !important;

		@media (min-width: 75rem) {
			margin-bottom: 0 !important;
		}
	}

	.center-content {
		display: flex !important;
    	flex-direction: column;
    	justify-content: center;
	}

	h4 {
		font-size: 1.875rem;
		font-weight: 800;
	}

	p {
		margin: 0 auto 3.5rem;
		max-width: 23rem;
		font-size: 1.125rem;
	}

	.share {

	}

	.input {
		display: inline-block;
		font-size: 1rem;
		height: 3.5rem;
		line-height: 1.5rem;
		padding: 1rem 0;
		margin-right: 0.5rem;
		width: 18rem;
		background: rgba(255,255,255,0.2);
		border: 1px solid white;
		border-radius: 3.5rem;
		color: white;
		text-align: center;
		font-family: font_default;
		font-weight: 600;
		width: 100%;

		@media (min-width: 75rem) {
			width: 18rem;
		}

		&:focus {
			outline: 0;
		}

		&::selection {
			color: white;
			background-color: pink_primary;
		}

	}

	.button {
		position: relative;
		display: inline-block;
		font-size: 1rem;
		height: 3.5rem;
		line-height: 1.5rem;
		padding: 1rem 1.5rem;
		background: transparent;
		border: 1px solid white;
		border-radius: 3.5rem;
		color: white;
		text-align: center;
		font-family: font_default;
		font-weight: 600;
		transition: all 0.2s ease;
		cursor: pointer;
		overflow: hidden;
		z-index: 1;
		width: 100%;
		margin-top: 1rem;
		outline: 0;

		@media (min-width: 75rem) {
			width: auto;
			margin-top: 0;
		}

		&::before {
			content: "";
			height: 100%;
			position: absolute;
			top: 0;
			left: -3.5rem;
			width: 3.5rem;
			background: white;
			transition: all 0.2s ease;
			will-change: transform;
			z-index: -1;
			border: solid 1px white;
			border-radius: 3.5rem;
		}


		&:hover {
			color: #e6007a;

			&::before {
				left: 0;
				width: 110%;
			}
		}

		&:active {
			transform: translateY(2px);
			outline: 0;
		}

		&:disabled {
			cursor: default;
			opacity: 0.5;

			&::before {
				display: none;
			}

			&:hover {
				color: inherit;
			}
		}
	}
`;
/* eslint-disable sort-keys */
import styled from "@xstyled/styled-components";
import React from "react";

interface Props {
	className?: string;
	isOpen?: boolean;
	url?: string;
	onClose?: () => void;
}

const ModalVideo = ({ className, isOpen, url, onClose }: Props) => {
	return (
		<React.Fragment>
			{ isOpen && (
				<div className={className}>
					<div className="modal">
						<div className="modal__bg" onClick={onClose}></div>
						<div className="modal__close" onClick={onClose}>
							<img src="close-light.svg" />
						</div>
						<div className="modal__video-wrapper">
							<div className="modal__video-inner">
								<video className="modal__video" controls autoPlay disablePictureInPicture controlsList="noplaybackrate nodownload">
									<source src={url} type="video/mp4"/>
								</video>
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default styled(ModalVideo)`
	@keyframes modalFadeIn {
		0%   {opacity: 0;};
		100% {opacity: 1};
	}

	@keyframes modalZoomIn {
		0%   {transform: scale(0.9)};
		100% {transform: scale(1)};
	}

	.modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1000;
		animation: modalFadeIn 0.4s ease;
	}

	.modal__bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.9);
		cursor: pointer;
	}

	.modal__video-wrapper {
		position: relative;
		width: calc(100% - 3rem);
		max-width: 80rem;
		animation: modalZoomIn 0.3s ease;
	}

	.modal__video-inner {
		position: relative;
		width: 100%;
		aspect-ratio: 16/9;
		max-height: 90vh;
		max-width: 80rem;
		margin: 0 auto;
	}

	.modal__video {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	.modal__close {
		position: absolute;
		top: 2rem;
		right: 2rem;
		width: 2.5rem;
		height: 2.5rem;
		cursor: pointer;
		z-index: 10;

		img {
			width: 2rem;
			height: 2rem;
		}
	}
`;
import styled from "@xstyled/styled-components";
import React from "react";
import { Container, Grid } from "semantic-ui-react";

interface Props {
	className?: string;
}

const BackgroundInformation = ({ className }: Props) => {
	return (
		<React.Fragment>
			<div className={className} id="background">
				<Container>
					<Grid relaxed>
						<Grid.Row className="background-info-container">
							<Grid.Column mobile={16} tablet={16} largeScreen={7} widescreen={7}>
								<h2>What is this about?</h2>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={16} largeScreen={9} widescreen={9}>
								<h4>This initiative gives DOT holders a voice in updating the Polkadot brand by casting a vote for their brand identity and logo design of preference.</h4>
								<p>Just as Polkadot’s technology evolves through decentralized governance, so must the Polkadot brand. While there are several established processes for updating code on a blockchain network, processes for updating a decentralized brand are still in their infancy. This initiative is an experiment in pushing the boundaries of social coordination by giving the community a voice on the future of Polkadot’s brand.</p>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column mobile={16} tablet={16} largeScreen={7} widescreen={7}>
								<h2>Why is this important?</h2>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={16} largeScreen={9} widescreen={9}>
								<h4>If you’re a Polkadot token holder, this is your opportunity to be heard. Take part in a historic moment in decentralized branding in a community powered by blockchain technology.</h4>
								<p>The vision of Polkadot is to build a Web3 future where technology empowers individuals to have more sovereignty over their decisions and interactions. Decentralizing power means distributing it across a wide base rather than concentrating it in the hands of a few. For this world to take shape, active community participation in governance is needed.</p>
								<p>The Polkadot ecosystem has expanded immensely since its launch, with a subsequent change in its brand needs. It’s only by ensuring that the Polkadot community’s input and voice is heard that its brand can evolve to truly serve the needs of the ecosystem. While no system is perfect, novel experiments like this can make great breakthroughs with decentralized branding.</p>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default styled(BackgroundInformation)`
	.ui.container {
		margin-top: 0;
		padding-top: 4rem;
	}

	.background-info-container {
		margin-bottom: 8rem;
	}
`;